import React, { useRef, useEffect, useCallback } from "react";
import { useViewer } from "../../viewerContext";
import { Viewer, PluginConstructor } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { VirtualTourPlugin } from "@photo-sphere-viewer/virtual-tour-plugin";
import { AutorotatePlugin } from "@photo-sphere-viewer/autorotate-plugin";
import { ReactPhotoSphereViewer, ViewerAPI } from "react-photo-sphere-viewer";
import { CompassPlugin } from "@photo-sphere-viewer/compass-plugin";
import "@photo-sphere-viewer/core/index.css"
import "@photo-sphere-viewer/markers-plugin/index.css"
import "@photo-sphere-viewer/virtual-tour-plugin/index.css"
import "@photo-sphere-viewer/compass-plugin/index.css"
import { events as vtp_events } from "@photo-sphere-viewer/virtual-tour-plugin";

export default function PanoramaViewer({ url, sensorScanId }: { url: string, sensorScanId: number }) {
  const { viewerState, setViewerState } = useViewer();
  const panoViewer = useRef<ViewerAPI>(null);
  const panoTour = useRef<VirtualTourPlugin | null>(null);
  const nodeRef = useRef<number>(viewerState.panoId);

  const getFunc = async (nodeId: number) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/sensorscans/${sensorScanId}/${nodeId}?save_format=jpg`
    );

    var data = await response.json();

    data.panorama = `${url}${nodeId}.jpg`;

    return data;
  };

  const navbar = ["autorotate", "zoom", "move", "caption", "fullscreen"];
  const plugins: (PluginConstructor | [PluginConstructor, any])[] = [
    MarkersPlugin,
    [
      VirtualTourPlugin,
      {
        positionMode: "gps",
        dataMode: "server",
        startNodeId: viewerState.panoId,
        preload: true,
        transitionOptions: {
          showLoader: false,
          effect: 'fade',
          rotation: false,
        },
        getNode: getFunc,
      },
    ],
    [
      AutorotatePlugin, {
        autorotateSpeed: "2rpm",
        autostartOnIdle: false,
        autostartDelay: Infinity,
      }
    ],
    [
      CompassPlugin,
      {
        position: "top right"
      }
    ],
  ]

  const handlePanoArrowClick = useCallback((node_id: string) => {

    nodeRef.current = +node_id;
    if (nodeRef.current !== viewerState.panoId) {
      setViewerState({
        type: "UPDATE_FROM_PANORAMA",
        payload: { id: nodeRef.current },
      });
    }
  }, [setViewerState, viewerState.panoId]);

  const handleReady = (instance: Viewer) => {
    const virtualTourPlug: VirtualTourPlugin = instance.getPlugin(VirtualTourPlugin);
    panoTour.current = virtualTourPlug;

    virtualTourPlug.addEventListener(vtp_events.NodeChangedEvent.type, {
      handleEvent: (e: vtp_events.NodeChangedEvent) => {
        handlePanoArrowClick(e.node.id);
      }
    });
  }

  useEffect(() => {
    if (panoTour.current && (viewerState.updater !== "panorama")) {
      panoTour.current.setCurrentNode(String(viewerState.panoId));
    }
  }, [viewerState])

  return (
    <ReactPhotoSphereViewer
      src={`${url}${nodeRef.current}.jpg`}
      height="100vh"
      width="100vw"
      ref={panoViewer}
      defaultZoomLvl={30}
      minFov={10}
      maxFov={100}
      navbar={navbar}
      onReady={handleReady}
      plugins={plugins}
    />
  );
}
