import { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useViewer } from "../../viewerContext";
import { transform_coordinate } from "../../utilities/helper_proj4";
import { atan2 } from "mathjs";
import { Vector3 } from "three";

const Potree = window.Potree;

const PointCloudViewer = (props, ref) => {
  const { viewerState } = useViewer();

  const potreeContainerDiv = useRef("");
  const potreeViewer = useRef();

  useEffect(() => {
    if (potreeViewer.current) return;

    potreeViewer.current = new Potree.Viewer(potreeContainerDiv.current);
    potreeViewer.current.setEDLEnabled(true);
    potreeViewer.current.setFOV(60);
    potreeViewer.current.setPointBudget(5 * 1000 * 1000);
    potreeViewer.current.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    potreeViewer.current.loadSettingsFromURL();
    potreeViewer.current.setControls(potreeViewer.current.earthControls);
    potreeViewer.current.loadGUI(() => {
      potreeViewer.current.setLanguage("en");
      potreeViewer.current.toggleSidebar();
    });

    const load_multiple_pointclouds = async function (num_of_pc) {
      for (let i = 1; i <= num_of_pc; i++) {
        await Potree.loadPointCloud(
          props.url + "/Part_" + i.toString() + "/metadata.json",
          "pc_" + i.toString(),
          function (e) {
            potreeViewer.current.scene.addPointCloud(e.pointcloud);
            let material = e.pointcloud.material;
            material.size = 0.25;
            material.pointSizeType = Potree.PointSizeType.FIXED;
            material.shape = Potree.PointShape.CIRCLE;
            // material.activeAttributeName = "intensity gradient";
            material.intensityRange = [64, 4096];
            let hasRGBA =
              e.pointcloud
                .getAttributes()
                .attributes.find((a) => a.name === "rgba") !== undefined;
            let hasIntensity =
              e.pointcloud
                .getAttributes()
                .attributes.find((a) => a.name === "intensity") !== undefined;

            if (hasRGBA) {
              material.activeAttributeName = "rgba";
            } else if (hasIntensity) {
              material.activeAttributeName = "intensity";
            } else {
              material.activeAttributeName = "color";
            }
            material.rgbGamma = 0.8;
          }
        );
      }
    };
    load_multiple_pointclouds(props.num_pc).catch((error) => {});
  }, [props]);

  useImperativeHandle(ref, () => ({
    getAlert() {
      return potreeViewer.current.scene.getActiveCamera().position;
    },
  }));

  useEffect(() => {
    if (!potreeViewer.current) return;
    const camPos = transform_coordinate(
      viewerState.data.features[viewerState.mapId].geometry.coordinates,
      "EPSG:4326",
      "EPSG:3006",
      3
    );
    const nextCamPos = transform_coordinate(
      viewerState.data.features[viewerState.mapId + 1].geometry.coordinates,
      "EPSG:4326",
      "EPSG:3006",
      3
    );

    const startPosition = new Vector3(camPos[0], camPos[1], camPos[2]);
    const endPosition = new Vector3(
      nextCamPos[0],
      nextCamPos[1],
      nextCamPos[2]
    );

    const heading = endPosition.sub(startPosition);
    const yaw = atan2(heading.x, heading.z);

    potreeViewer.current.scene.view.pitch = 0.0;
    potreeViewer.current.scene.view.position.set(
      camPos[0],
      camPos[1],
      camPos[2] + 5
    );
    potreeViewer.current.scene.view.yaw = yaw;
  });

  return (
    <div id="potree-root" className={"potree_container"}>
      <div
        id="potree_render_area"
        ref={potreeContainerDiv}
        style={{ height: "100vh" }}
      />
      <div id="potree_sidebar_container" />
    </div>
  );
};

export default forwardRef(PointCloudViewer);
